import React from "react"

import Layout from "../../components/layout"
import QuickForm from "../../components/QuickForm/index"
import { deCH } from "../../lang/de-CH"

export default function QuickFormPage() {

  return (
    <Layout location="CH" language="de-CH">
      <QuickForm txt={deCH} language="de-CH"/>
    </Layout>
  )
}